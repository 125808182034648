import React, { Component } from 'react'
import './App.css';
import Home from './pages/Home.js'
import Jobs from './pages/Jobs.js'
import About from './pages/About.js'
import Contact from './pages/Contact.js'
import Service from './pages/Service.js'
import { HashRouter as Router, Routes, Route } from 'react-router-dom'
import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';


export default class App extends Component {
  render() {
    return (
      <Router>
        <NotificationContainer/>
      <Routes>
        <Route key='home'  path='/' element={<Home/>}  />
        <Route exact path='/about' element={<About/>}  key='about' />
        <Route exact path='/contact' element={<Contact/>} key='contact'  />
        <Route exact path='/jobs' element={<Jobs/>} key='jobs'  />
        <Route exact path='/service' element={<Service/>}  key='service'  />

      </Routes>
    </Router>
     
    )
  }
}

